import React from "react"
import ReactCountryFlag from "react-country-flag"


const CountryFlag = ({ isoCode }) => {
    if (!isoCode || isoCode.length !== 2) {
        return <span role="img">{isoCode}</span>; // Affiche un placeholder en cas de code ISO invalide
    }

    return (
        <span role="img" style={{fontSize:'1.5em'}}><ReactCountryFlag
            style={{width:'1em',
                height:'',
                boxShadow:'0 0 2px 1px #ddd',
        }} countryCode={isoCode} svg/></span>
    );
};



export default CountryFlag;
