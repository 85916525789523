import React from "react";
import {Row, Col, Container} from "react-bootstrap";
import Share from "./Share";
import Bookmarks from "./Bookmarks";

const Footer = () => {
    return (
            <Container fluid={true} style={{paddingTop:'10px', borderTop:'1px silver solid', borderRadius:"10px 10px 0 0"}}>
            <Row >
                <Col xs={12} style={{ textAlign: "center" }}>
                    <Row>
                        <Col md={6} xs={6} style={{ textAlign: "center" }}>
                            <Share />
                        </Col>
                        <Col  md={6} xs={6} style={{ textAlign: "center" }}>
                            <Bookmarks />
                        </Col>
                    </Row>
                </Col>
            </Row>
            </Container>

    );
};

export default Footer;
