import React, { useState, useEffect } from "react";
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from "react-simple-maps";
import countries from "./data/countries.json"; // Coordonnées des pays
import geoUrl from "./data/world-countries.json"; // Carte du monde
import gridSet from "../Medias/Gallery_0/GridSet";
import CountryFlag from "./CountryFlag"; // Données des recettes
import { Link } from "react-router-dom";
import { Modal, Button, Container } from "react-bootstrap";
import logo from "./apple-touch-icon.png";
import noel from "./1noel.png";





function RecipeMap() {
    const [modalShow, setModalShow] = useState(false);
    const [selectedRecipes, setSelectedRecipes] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [mapWidth, setMapWidth] = useState(window.innerWidth);
    const [isMapReduced, setIsMapReduced] = useState(true);

    // Fonction pour calculer la hauteur dynamique de la carte
    const calculateMapHeight = () => Math.min(window.innerHeight * 0.4, 400);

    // Gestion de la mise à jour de la largeur de la fenêtre
    useEffect(() => {
        const handleResize = () => setMapWidth(window.innerWidth);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Enrichir les recettes avec leurs coordonnées de pays
    const enrichedRecipes = gridSet.map((recipe) => {
        const country = countries.find((c) => c.key === recipe.flag);
        if (country) {
            return {
                ...recipe,
                lat: country.latitude,
                lng: country.longitude,
            };
        }
        return recipe;
    });

    // Grouper les recettes par coordonnées pour éviter les chevauchements
    const recipesByCountry = enrichedRecipes.reduce((acc, recipe) => {
        if (recipe.lat && recipe.lng) {
            const key = `${recipe.lat}-${recipe.lng}`;
            if (!acc[key]) acc[key] = [];
            acc[key].push(recipe);
        }
        return acc;
    }, {});

    // Fonction pour fermer le modal
    const closeModalOnly = () => {
        setTimeout(() => {
            setModalShow(false);
        }, 300);
    };


    return (
        <Container fluid style={{ textAlign: "center", padding: "0px", margin: "0px", background: "#2196F3" }}>
            <div
                style={{
                    position: "relative",
                    height: isMapReduced ? "80px" : `${calculateMapHeight()}px`,
                    maxWidth: `${mapWidth}px`,
                    overflow: "hidden",
                    maxHeight: "500px",
                    width: "100%",
                    transition: "height 0.3s ease-out",
                }}
            >
                <ComposableMap
                    projectionConfig={{ scale: 300, center: [0, 20] }}
                    style={{ width: "100%", height: "100%" }}
                >
                    <ZoomableGroup>
                        <Geographies geography={geoUrl}>
                            {({ geographies }) =>
                                geographies.map((geo) => {
                                    const isLand = geo.properties.ISO_A2 !== "00";
                                    return (
                                        <Geography
                                            key={geo.rsmKey}
                                            geography={geo}
                                            tabIndex={-1} // Empêche le focus par défaut
                                            style={{
                                                default: {
                                                    fill: isLand ? "#4CAF50" : "#96D2FF",
                                                    stroke: "#efefef",
                                                    strokeWidth: 0.5,
                                                },
                                                hover: {
                                                    fill: isLand ? "#4CAF50" : "#96D2FF",
                                                    stroke: "#efefef",
                                                    strokeWidth: 1,
                                                },
                                                pressed: {
                                                    fill: "#FF4500",
                                                    stroke: "#FF4500",
                                                },
                                            }}
                                        />
                                    );
                                })
                            }
                        </Geographies>
                        {/* Affichage des marqueurs spéciaux */}
                        {enrichedRecipes.map((recipe, index) => {
                            if (recipe.special_marker) {
                                // Vérifie s'il y a un marqueur spécial
                                return (
                                    <Marker
                                        key={index}
                                        coordinates={[recipe.special_marker.coordinates.longitude, recipe.special_marker.coordinates.latitude]}
                                        onClick={() => {
                                            setSelectedCountry(recipe.country +' - inspiration de Noël' || "Pays inconnu");
                                            setSelectedRecipes([recipe]); // Utiliser le tableau contenant la recette individuelle
                                            setModalShow(true);
                                        }}
                                    >
                                        <image
                                            href={noel}
                                            width={60}
                                            height={60}
                                            transform="translate(-15,-15)"
                                        />
                                    </Marker>
                                );
                            }
                            return null;
                        })}

                        {Object.values(recipesByCountry).flatMap((recipes) =>
                            recipes.map((recipe, index) => (
                                <Marker
                                    key={index}
                                    coordinates={[recipe.lng, recipe.lat]}
                                    onClick={() => {
                                        // Utilise directement le pays provenant de `recipe` dans `gridSet`
                                        setSelectedCountry(recipe.country || "Pays inconnu");
                                        setSelectedRecipes(recipes); // Liste des recettes du pays
                                        setModalShow(true);
                                    }}
                                >
                                    <image
                                        href={logo}
                                        width={30}
                                        height={30}
                                        transform="translate(-15,-15)"
                                    />

                                    <image
                                        href={logo}
                                        width={30}
                                        height={30}
                                        transform="translate(-15,-15)"
                                    />
                                </Marker>
                            ))
                        )}
                    </ZoomableGroup>
                </ComposableMap>
            </div>

            <Modal show={modalShow} onHide={() => closeModalOnly()} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedCountry || "Pays inconnu"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedRecipes.length > 0 ? (
                        <ul style={{ padding: 0, margin: 0, listStyle: "none" }}>
                            {selectedRecipes.map((recipe, index) => (
                                <li key={index} style={{ marginBottom: "10px" }}>
                                    <CountryFlag isoCode={recipe.flag} /> &nbsp;
                                    <Link
                                        to={recipe.detailURL}
                                        style={{ textDecoration: "none", color: "#007bff" }}
                                        onClick={() => {
                                            setIsMapReduced(true);
                                            closeModalOnly();
                                        }}
                                    >
                                        {recipe.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>Aucune recette disponible.</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => closeModalOnly()}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>

            {isMapReduced ? (
                <Button
                    title='Open Chicken Map BG'
                    variant="primary"
                    onClick={() => setIsMapReduced(false)}
                    style={{ margin: "5px 0" }}
                >
                    <span style={{fontSize: '0.6em'}}>Open Chicken Map BG</span><br/>
                    <img alt='Open Chicken Map BG' src={logo} style={{height: '25px', width: '25px'}}/><br/>
                </Button>
            ) : (
                <Button
                    title="Reduce Map"
                    variant="primary"
                    onClick={() => setIsMapReduced(true)}
                    style={{margin: "5px 0"}}
                ><span style={{fontSize: '0.6em'}}>Reduce Chicken Map BG</span><br/>
                    <img alt='Reduce Chicken Map BG' src={logo} style={{height: '25px', width: '25px'}}/><br/>
                </Button>
            )}
        </Container>
    );
}

export default RecipeMap;
