import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import MarinadesDropdown from "./NavDropDownCountry";
import DropDownCooking from "./DropDownCooking";
import DropDownChickenPart from "./DropDownChickenPart";
import DropdownCrispy from './DropDownCrispy.js';
import Image from "react-bootstrap/Image";
import MainLogo from "../Medias/poulet-sans-fond-lettrage-b.png";
import { Link } from "react-router-dom";
import SelectTitle from "./SelectTitle";
import { FaDownload } from 'react-icons/fa';


function SearchForm() {
    const [isPwaInstallable, setIsPwaInstallable] = useState(false);
    const [installPromptEvent, setInstallPromptEvent] = useState(null);
    const [isInAppBrowser, setIsInAppBrowser] = useState(false);

    useEffect(() => {
        // Fonction de détection du navigateur intégré
        const detectInAppBrowser = () => {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            return /FBAN|FBAV|Instagram/.test(userAgent); // Détecte Facebook ou Instagram
        };

        // Mettre à jour l'état du navigateur intégré
        setIsInAppBrowser(detectInAppBrowser());

        // Gestionnaire de l'événement 'beforeinstallprompt' pour détecter l'installabilité de la PWA
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setInstallPromptEvent(e); // Stocke l'événement pour l'utilisation future
            setIsPwaInstallable(true); // Indique que la PWA est installable
        };

        // Écoute l'événement 'beforeinstallprompt'
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        // Nettoyage
        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    // Fonction pour démarrer l'installation de la PWA
    const handleInstallClick = () => {
        if (installPromptEvent) {
            installPromptEvent.prompt(); // Affiche l'invite d'installation

            installPromptEvent.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                // Réinitialise l'événement
                setInstallPromptEvent(null);
                setIsPwaInstallable(false);
            });
        }
    };

    return (
        <Container fluid style={{
            background: "linear-gradient(170deg, #000, #666)",
            marginBottom: "0px",
            padding: "20px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)"
        }}>
            <Row className="align-items-center">
                <Col xs={12} md={4} className='d-flex flex-column align-items-center'>
                    <h1 style={{
                        marginBottom: "10px",
                        marginTop: "0px",
                        textAlign: "center",
                        color: "white",
                        fontSize: "1em",
                        marginLeft: "30px"
                    }}>
                        <span style={{color: "orange", fontSize:'0.8em'}}>Des recettes de marinades à travers le monde</span><br />

                    </h1>


                    {/* Logo avec lien */}
                    <Link to="/" title="Revenir à l'accueil de marinade de poulet"
                          style={{zIndex: "1", textDecoration: 'none', display: 'flex', alignItems: 'center'}}>
                        <Image
                            style={{ height: "100px", width: "100px", zIndex: "1", marginBottom: "10px" }}
                            src={MainLogo}
                            alt="marinade-poulet.com"
                            className="d-none d-md-block"
                        />
                        <Image
                            style={{ height: "50px", width: "50px", zIndex: "1000", marginBottom: "10px" }}
                            src={MainLogo}
                            alt="marinade-poulet.com"
                            className="d-block d-md-none"
                        />
                    </Link>
                    <p style={{color: "#fff", marginTop:'-15px'}}><span style={{color: "#fff", fontSize:'0.8em'}}>pour transformer votre poulet !</span></p>

                    {/* Affichage conditionnel des boutons en fonction de l'environnement */}
                    {isInAppBrowser ? (
                        // Affiche un message pour copier le lien et l'ouvrir dans le navigateur natif
                        <p style={{background:"#efefef",textAlign:"center",border:"inset 2px #fff", padding:"5px", color:"#333", fontSize:"0.8em"}}>
                            Pour bénéficier de toutes les fonctionnalités PWA merci d'ouvrir la page dans votre navigateur.
                        </p>
                    ) : (
                        // Affiche le bouton d'installation de la PWA si disponible
                        isPwaInstallable && (
                            <button onClick={handleInstallClick} style={{ color: "#111", fontSize: "1em", marginTop: "-5px",marginBottom: "10px", background: "orange", border: "1px", cursor: "pointer", textAlign: "center"}}>
                                <i>Installez l'application </i> <FaDownload style={{ color: "#111", marginLeft: "5px", fontSize: "1em" }}/>
                            </button>
                        )
                    )}
                </Col>

                {/* Menu de sélection */}
                <Col xs={12} md={8}>
                    <Row className="text-center">
                        <Col xs={6} md={3} className="text-center" style={{marginTop:'-20px'}}>
                            <DropDownCooking/>
                        </Col>
                        <Col xs={6} md={3} className="text-center" style={{marginTop:'-20px'}}>
                            <MarinadesDropdown/>
                        </Col>
                        <Col xs={6} md={3} className="text-center" style={{marginTop:'-20px'}}>
                            <DropDownChickenPart/>
                        </Col>
                        <Col xs={6} md={3} className="text-center" style={{marginTop:'-20px'}}>
                            <DropdownCrispy/>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* Autres éléments du formulaire de recherche */}
            <Row style={{marginTop:'-30px'}}>
                <Col><SelectTitle/></Col>
            </Row>

        </Container>

    );
}

export default SearchForm;
