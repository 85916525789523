import React, { useEffect, useState } from 'react';
import CookieConsentBanner from './CookieConsentBanner';
import { updateConsentSettings } from './gtm'; // Mettre à jour les paramètres dans la dataLayer
import { useLocation } from 'react-router-dom'; // Assurez-vous d'avoir cette dépendance
import SidebarMenu from './../SidebarMenu';

const CookieConsentGTM = () => {
    const location = useLocation(); // Récupère la localisation actuelle
    const [showBanner, setShowBanner] = useState(false); // Gestion de l'affichage de la bannière
    const [consentSettings, setConsentSettings] = useState({
        necessary: true,
        analytics: true,
        marketing: false,
    });

    useEffect(() => {
        // Vérifier si le consentement est déjà stocké
        const storedConsent = localStorage.getItem('cookieConsentSettings');
        if (storedConsent) {
            const parsedConsent = JSON.parse(storedConsent);
            setConsentSettings(parsedConsent); // Récupérer les paramètres de consentement stockés
            updateConsentSettings(parsedConsent); // Mettre à jour la dataLayer avec les paramètres
        } else {
            setShowBanner(true); // Afficher la bannière si pas de consentement
        }
    }, []);

    // Pousser un événement dans le dataLayer lors du changement de page
    useEffect(() => {
        // Écouter les changements de route et pousser un événement dans le dataLayer
        window.dataLayer.push({
            event: 'page_view',
            page: {
                url: window.location.href,
                title: document.title,
                analyticsConsent: consentSettings.analytics, // Utiliser consentSettings
                marketingConsent: consentSettings.marketing, // Utiliser consentSettings
            },
        });
    }, [location, consentSettings]); // Met à jour à chaque changement de location ou de consentement

    const handleSaveConsent = (settings) => {
        setConsentSettings(settings);
        setShowBanner(false); // Masquer la bannière après sauvegarde
        localStorage.setItem('cookieConsentSettings', JSON.stringify(settings)); // Enregistrer les paramètres
        updateConsentSettings(settings); // Mettre à jour la dataLayer avec les nouveaux paramètres
    };

    // Fonction pour afficher la bannière de consentement quand l'utilisateur clique sur "Préférences cookies"
    const showCookiePreferences = () => {
        setShowBanner(true); // Réafficher la bannière
    };

    return (
        <>
            {showBanner && (
                <CookieConsentBanner onSaveConsent={handleSaveConsent} />
            )}
            <SidebarMenu onShowCookiePreferences={showCookiePreferences}/>
        </>
    );
};

export default CookieConsentGTM;
