// InstallApp.js
import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Container, Row, Col, Button} from 'react-bootstrap';
import {FaDownload, FaCopy} from 'react-icons/fa';

const InstallApp = () => {
    const [isInstalled, setIsInstalled] = useState(false);
    const [installPromptEvent, setInstallPromptEvent] = useState(null);
    const [isInAppBrowser, setIsInAppBrowser] = useState(false);

    useEffect(() => {
        // Détecter si l'utilisateur est dans un navigateur intégré (Facebook, Instagram)
        const detectInAppBrowser = () => {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            return /FBAN|FBAV|Instagram/.test(userAgent); // Détecte Facebook ou Instagram
        };

        // Mettre à jour l'état du navigateur intégré
        setIsInAppBrowser(detectInAppBrowser());

        const checkIfInstalled = () => {
            const isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches;
            setIsInstalled(isInStandaloneMode);
        };

        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            setInstallPromptEvent(e);  // Stocke l'événement pour afficher l'invite plus tard
        });

        checkIfInstalled();
        window.addEventListener('appinstalled', checkIfInstalled);

        return () => {
            window.removeEventListener('appinstalled', checkIfInstalled);
        };
    }, []);

    const handleInstallClick = () => {
        if (installPromptEvent) {
            installPromptEvent.prompt();
            installPromptEvent.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                setInstallPromptEvent(null); // Réinitialise l'événement après l'invite
            });
        }
    };

    // Fonction pour copier le lien dans le presse-papiers
    const copyLinkToClipboard = () => {
        navigator.clipboard.writeText(window.location.href).then(() => {
            alert("Lien copié ! Ouvrez votre navigateur et collez-le pour accéder à cette page.");
        });
    };

    return (
        <Container style={{backgroundColor: "#fff", marginTop: "20px", padding: "20px"}}>
            <Helmet>
                <title>Installer l'Application PWA</title>
                <meta name="description"
                      content="Instructions pour installer notre application de recettes de marinade de poulet."/>

                {/* JSON-LD pour l'application */}
                <script type="application/ld+json">
                    {`
    {
      "@context": "https://schema.org",
      "@type": "SoftwareApplication",
      "name": "PWA - Recettes de Poulet - marinade-poulet.com",
      "operatingSystem": "Android, iOS, Web",
      "url": "https://marinade-poulet.com/installation",
      "applicationCategory": "RecipeApp",
      "applicationSubCategory": "CookingApp",
      "softwareVersion": "1.3",
      "description": "Explorez une collection exclusive de recettes savoureuses pour marinades de poulet, découvrez de nouvelles saveurs et sauvegardez vos préférées pour les retrouver facilement, même hors ligne !",
      "identifier": "com.marinade-poulet.recettes",
      "downloadUrl": "https://marinade-poulet.com/installation",
      "screenshot": [
        "https://marinade-poulet/Screenshot-install-app.jpg",
        "https://marinade-poulet/android-chrome-512x512.png",
        "https://marinade-poulet/Screenshot_dish-card.jpg",
        "https://marinade-poulet/Screenshot-installed-app.jpg",
        "https://marinade-poulet/Screenshot_list-recipes-cat.jpg",
        "https://marinade-poulet/Screenshot_recipe-step.jpg"
      ],
      "image": "https://exemple.com/images/app-icon.png",
      "author": {
        "@type": "Organization",
        "name": "marinade-poulet.com"
      },
      "offers": {
        "@type": "Offer",
        "priceCurrency": "EUR",
        "price": "0",
        "url": "https://marinade-poulet.com/installation"
      },
      "publisher": {
        "@type": "Organization",
        "name": "Marinade-poulet-PWA",
        "url": "https://marinade-poulet.com/mentions-legales"
      },
      "audience": [
        {
          "@type": "Audience",
          "audienceType": "Amateurs de cuisine, passionnés de recettes"
        },
        {
          "@type": "Audience",
          "audienceType": "Technophiles, adeptes de rapidité et d'innovation"
        },
        {
          "@type": "Audience",
          "audienceType": "Passionnés de voyage et de culture, explorateurs de saveurs internationales"
        }
      ],
      "datePublished": "2024-11-09",
      "dateModified": "2024-11-10"
    }
    `}
                </script>

            </Helmet>

            {/* Si l'utilisateur est dans un navigateur intégré, afficher une invite pour copier le lien */}
            {isInAppBrowser ? (
                <Row className="text-center">
                    <Col>
                        <h1>Ouvrez dans le navigateur pour installer l'application</h1>
                        <p className="lead">
                            Pour installer notre application, copiez le lien ci-dessous et ouvrez-le dans votre
                            navigateur natif.
                        </p>
                        <Button
                            onClick={copyLinkToClipboard}
                            variant="warning"
                            style={{marginTop: "20px"}}
                        >
                            <FaCopy style={{marginRight: '8px'}}/> Copier le lien
                        </Button>
                    </Col>
                </Row>
            ) : (
                // Affiche le bouton d'installation de la PWA si disponible et non installée
                !isInstalled && (
                    <Row className="text-center">
                        <Col>
                            <h1>Profitez de notre Application PWA !</h1>
                            <p className="lead">
                                Installez notre application et accédez instantanément à des recettes de marinades du
                                monde entier, même hors ligne. Profitez d’une expérience rapide, sécurisée et
                                personnalisée sur votre appareil mobile ou ordinateur.
                            </p>
                            <Button
                                onClick={handleInstallClick}
                                variant="warning"
                                style={{marginTop: "20px"}}
                            >
                                <FaDownload style={{marginRight: '8px'}}/> Installer l'Application
                            </Button>
                        </Col>
                    </Row>
                )
            )}
            {isInstalled && (
                <Row className="text-center">
                    <Col>
                        <h1 style={{color: "darkorange"}}>Merci d'avoir installé notre Application PWA !</h1>
                        <p className="lead">
                            Vous profitez déjà d'une expérience optimale. L'application est bien installée sur votre
                            appareil. Bonnes recettes !
                        </p>
                        <Row className="text-center mt-4">
                            <Button variant="primary" href="/" style={{textDecoration: "none"}}>
                                Retourner au Catalogue
                            </Button>
                        </Row>
                    </Col>
                </Row>
            )}
            <hr/>

            {/* Avantages détaillés */}
            <Row className="mt-4">
                <Col xs={12} className="mb-4">
                    <h2>Pourquoi installer notre application ?</h2>
                    <ul style={{textAlign: "left", listStyleType: "none", paddingLeft: 0, lineHeight: "1.6"}}>
                        <li><strong><span role="img" aria-label="mobile phone">📱</span> Accès hors ligne :</strong> Une
                            fois installée, l'application fonctionne même sans connexion internet. Parfait pour cuisiner
                            en toute tranquillité où que vous soyez !
                        </li>
                        <li><strong><span role="img" aria-label="lightning bolt">⚡</span> Chargement rapide
                            :</strong> Conçue pour la performance, l'application offre une navigation ultra-rapide et
                            fluide, sans les délais de chargement d'un site web classique.
                        </li>
                        <li><strong><span role="img" aria-label="lock">🔒</span> Sécurité et confidentialité
                            :</strong> L'application fonctionne uniquement via des connexions sécurisées (HTTPS),
                            garantissant la protection de vos données personnelles.
                        </li>
                        <li><strong><span role="img" aria-label="desktop computer">🖥️</span> Facilité d'utilisation
                            :</strong> Ajoutez l'application directement à votre écran d'accueil ou bureau en quelques
                            clics. Pas besoin de la télécharger depuis une boutique d'applications !
                        </li>
                        <li><strong><span role="img" aria-label="megaphone">📣</span> Notifications push
                            :</strong> Recevez des alertes pour découvrir de nouvelles recettes ou des conseils de
                            cuisine dès qu'ils sont disponibles. Ne manquez jamais une nouvelle inspiration !
                        </li>
                        <li><strong><span role="img" aria-label="globe">🌍</span> Optimisée pour tous les appareils
                            :</strong> Que ce soit sur mobile, tablette ou ordinateur, l'application s'adapte à votre
                            écran pour une expérience optimale.
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
};

export default InstallApp;
