// MarinadesDropdown.js
import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import {FaGlobe} from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Import de Link pour éviter le rechargement

const MarinadesDropdown = () => {
    const marinades = [
        { title: "marinade de poulet aux saveurs asiatiques", to: "/categorie/recette/asiatique.html", text: "Asie" },
        { title: "marinade de poulet aux saveurs africaines", to: "/categorie/recette/afrique.html", text: "Afrique" },
        { title: "marinade de poulet aux saveurs caraïbéenes", to: "/categorie/recette/caraibes.html", text: "Caraïbes" },
        { title: "marinade de poulet aux saveurs européenes", to: "/categorie/recette/europe.html", text: "Europe" },
        { title: "marinade de poulet aux saveurs d'amérique du nord", to: "/categorie/recette/amerique-nord.html", text: "Amérique du nord" },
        { title: "marinade de poulet aux saveurs d'amérique du sud", to: "/categorie/recette/amerique-sud.html", text: "Amérique du sud" },
        { title: "marinade de poulet aux saveurs d'océanie", to: "/categorie/recette/oceanie.html", text: "Océanie" },
        { title: "marinade de poulet aux saveurs du Moyen Orient", to: "/categorie/recette/moyen-orient.html", text: "Moyen-Orient" },
        { title: "marinade de poulet fusion des cultures", to: "/categorie/recette/fusion.html", text: "Fusion des cultures" }
    ];

    const color = "rgba(64, 224, 208, 1)";
    const dropdownItemStyle = {
        zIndex: 1000,
        backgroundColor: color,
        color: 'black',
        fontWeight: "bolder",
        transition: 'background-color 0.3s ease',
        border: "thin solid white",
    };

    return (
        <NavDropdown
            id="drop-country"
            style={{ color: "black" }}
            title={
                <div title="Explorez les saveurs des continents" style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                  <span
                      className="dropDownLabel"
                      style={{
                          marginTop: "5px",
                          color: color,
                          textAlign: "center",
                          fontSize: "1em"
                      }}
                  >
                      Au 4 coins du monde
                  </span>
                    <FaGlobe
                        style={{ height: "50px", width: "50px", color: color,  marginTop: "5px" }}
                        className="d-none d-md-block"
                    />
                    <FaGlobe
                        style={{ height: "30px", width: "30px", color: color,  marginTop: "5px" }}
                        className="d-block d-md-none"
                    />
                </div>
            }
            className="nav-dropdown-title"
        >
            {marinades.map((item, index) => (
                <NavDropdown.Item as={Link} key={index} to={item.to} style={dropdownItemStyle} title={item.title}>
                    <sup style={{ fontSize: "8px" }}>Marinade de poulet en</sup> {item.text}
                </NavDropdown.Item>
            ))}
        </NavDropdown>
    );
}

export default MarinadesDropdown;
