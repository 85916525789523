import React, { useEffect, useState } from "react";
import { Container, Row, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import CountryFlag from "./CountryFlag";
import "./Share.css";
import RecipeMap from "./RecipeMap";

const RecentRecipesSelectHistory = () => {
    const [recentRecipes, setRecentRecipes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isMapVisible, setIsMapVisible] = useState(true);

    // Charger les recettes récentes depuis le localStorage au montage
    useEffect(() => {
        const storedRecipes = JSON.parse(localStorage.getItem("recentRecipes")) || [];
        setRecentRecipes(storedRecipes);
    }, []);

    const handleClose = () => setShowModal(false);

    // Gestion du clic sur une recette
    const handleRecipeClick = () => {
        setIsMapVisible(true); // Masquer la carte après le clic sur une recette
        handleClose(); // Fermer la modal
    };

    return (
            <Container fluid>
                {isMapVisible && (
                    <Row
                        style={{
                            marginBottom: "15px",
                            backgroundColor: "#fff",
                        }}
                    >
                        {/* Clé dynamique pour forcer le re-rendu */}
                        <RecipeMap key={isMapVisible ? "visible" : "hidden"} />
                    </Row>
                )}
                <Row
                    style={{
                        marginBottom: "-15px",
                        backgroundColor: "#fff",
                        paddingTop: "10px",
                        marginTop: "-20px",
                        background:'transparent',
                    }}
                >
                </Row>
                {/* Modal pour afficher les recettes récentes */}
                <Modal show={showModal} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Vues récentes</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {recentRecipes.map((recipe, index) => (
                            <Link
                                key={index}
                                to={recipe.detailURL}
                                style={{
                                    fontSize: "1em",
                                    display: "block",
                                    padding: "10px 0",
                                    color: "#333",
                                    textDecoration: "none",
                                }}
                                onClick={() => handleRecipeClick()}
                            >
                                <CountryFlag isoCode={recipe.flag} />
                                &nbsp;{recipe.title}
                            </Link>
                        ))}
                    </Modal.Body>
                </Modal>
            </Container>
    );
};

export default RecentRecipesSelectHistory;
